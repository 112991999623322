ol,
ol li {
  list-style: decimal;
}

#blog-single-post-content h2,
#blog-single-post-content h3,
#blog-single-post-content h4,
#blog-single-post-content h5 {
  font-weight: 700;
  font-family: "Avalon", Arial, sans-serif;
  letter-spacing: -0.01em;
}

#blog-single-post-content h2 {
  font-size: 2rem;
}

#blog-single-post-content hr:not([class]):not([style]) {
  margin: 2em 0;
  padding: 0;
  border: none;
  border-bottom: 1px solid #ddd;
}

#blog-single-post-content p:not([class]):not([style]) {
  line-height: 1.8em;
  margin: 0 0 2em;
  padding: 0;
}

#blog-single-post-content ul:not([class]):not([style]),
#blog-single-post-content ol {
  padding-left: 2rem;
  line-height: 1.8em;
  margin-bottom: 2em;
}

#blog-single-post-content ul:not([class]):not([style]),
#blog-single-post-content ul li:not([class]):not([style]) {
  list-style: disc;
}

#blog-single-post-content ul li:not([class]):not([style]),
#blog-single-post-content ol li {
  line-height: 1.8em;
  display: list-item;
  margin-bottom: 1em;
}

#blog-single-post-content .aligncenter {
  margin: 0 auto 1.5rem;
  display: block;
}

#blog-single-post-content span:not([class]):not([style]) {
  font-weight: inherit !important;
  font-size: inherit !important;
  color: inherit !important;
}

#blog-single-post-content blockquote:not([class]):not([style]) {
  font-size: 0.9em;
  margin: 0 0 1em;
  border-left: 5px solid #eee;
  font-weight: normal;
  padding-left: 1.2em;
  font-family: Georgia, "Times New Roman", Times, serif;
  font-style: italic;
}

@media only screen and (min-width: 576px) {
  #blog-single-post-content blockquote {
    margin-left: 0;
    border-left-width: 0.6em;
    font-size: 1.1em;
  }
}

#blog-single-post-content blockquote > div:not([class]):not([style]) {
  line-height: 1.8em;
  margin: 0 0 2em;
}

#blog-single-post-content .wp-caption-text,
#blog-single-post-content .wp-block-image .aligncenter > figcaption,
#blog-single-post-content .wp-block-image .alignleft > figcaption,
#blog-single-post-content .wp-block-image .alignright > figcaption,
#blog-single-post-content .wp-block-image.is-resized > figcaption {
  margin-top: 0.8em;
  line-height: 1.4;
  font-size: 0.8em;
  font-weight: bold;
  width: 100% !important;
  display: block;
}

#blog-single-post-content .alignleft {
  display: inline;
  float: left;
  margin-right: 1.5em;
}

#blog-single-post-content .alignright {
  display: inline;
  float: right;
  margin-left: 1.5em;
}
#blog-single-post-content .aligncenter,
#blog-single-post-content .alignleft,
#blog-single-post-content .alignright {
  margin-bottom: 1.5em;
  max-width: 300px;
  height: auto;
  width: auto;
}
#blog-single-post-content .aligncenter {
  max-width: none;
}

#blog-single-post-content h1 a:not([class]):not([style]),
#blog-single-post-content h2 a:not([class]):not([style]),
#blog-single-post-content h3 a:not([class]):not([style]),
#blog-single-post-content h4 a:not([class]):not([style]),
#blog-single-post-content h5 a:not([class]):not([style]),
#blog-single-post-content p a:not([class]):not([style]),
#blog-single-post-content ul a:not([class]):not([style]),
#blog-single-post-content ol a:not([class]):not([style]),
#blog-single-post-content table a:not([class]):not([style]) {
  color: #f70564;
  font-weight: bold;
}

#blog-single-post-content h1 a:not([class]):not([style]):hover,
#blog-single-post-content h2 a:not([class]):not([style]):hover,
#blog-single-post-content h3 a:not([class]):not([style]):hover,
#blog-single-post-content h4 a:not([class]):not([style]):hover,
#blog-single-post-content h5 a:not([class]):not([style]):hover,
#blog-single-post-content p a:not([class]):not([style]):hover,
#blog-single-post-content ul a:not([class]):not([style]):hover,
#blog-single-post-content ol a:not([class]):not([style]):hover,
#blog-single-post-content table a:not([class]):not([style]):hover {
  text-decoration: underline;
}

#blog-single-post-content p a i:not([class]):not([style]),
#blog-single-post-content table a i:not([class]):not([style]) {
  font-style: inherit;
}

/* WP Block Gallery Images */

#blog-single-post-content figure:not([class]):not([style]) {
  margin: 1em 0;
}
@media (min-width: 600px) {
  #blog-single-post-content figure:not([class]):not([style]) {
    margin: 1em 40px;
  }
}

#blog-single-post-content .wp-block-gallery {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  padding: 0;
}

#blog-single-post-content .wp-block-gallery .blocks-gallery-image,
#blog-single-post-content .wp-block-gallery .blocks-gallery-item {
  margin: 0 16px 16px 0;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

#blog-single-post-content .wp-block-gallery .blocks-gallery-image figure,
#blog-single-post-content .wp-block-gallery .blocks-gallery-item figure {
  margin: 0;
  height: 100%;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  #blog-single-post-content .wp-block-gallery .blocks-gallery-image figure,
  #blog-single-post-content .wp-block-gallery .blocks-gallery-item figure {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
  }
}

#blog-single-post-content .wp-block-gallery .blocks-gallery-image img,
#blog-single-post-content .wp-block-gallery .blocks-gallery-item img {
  display: block;
  max-width: 100%;
  height: auto;
  width: 100%;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  #blog-single-post-content .wp-block-gallery .blocks-gallery-image img,
  #blog-single-post-content .wp-block-gallery .blocks-gallery-item img {
    width: auto;
  }
}

#blog-single-post-content .wp-block-gallery .blocks-gallery-image figcaption,
#blog-single-post-content .wp-block-gallery .blocks-gallery-item figcaption {
  position: absolute;
  bottom: 0;
  width: 100%;
  max-height: 100%;
  overflow: auto;
  padding: 40px 10px 5px;
  color: #fff;
  text-align: center;
  font-size: 13px;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0.3) 60%,
    transparent
  );
}

#blog-single-post-content
  .wp-block-gallery
  .blocks-gallery-image
  figcaption
  img,
#blog-single-post-content
  .wp-block-gallery
  .blocks-gallery-item
  figcaption
  img {
  display: inline;
}

#blog-single-post-content .wp-block-gallery.is-cropped .blocks-gallery-image a,
#blog-single-post-content
  .wp-block-gallery.is-cropped
  .blocks-gallery-image
  img,
#blog-single-post-content .wp-block-gallery.is-cropped .blocks-gallery-item a,
#blog-single-post-content
  .wp-block-gallery.is-cropped
  .blocks-gallery-item
  img {
  width: 100%;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  #blog-single-post-content
    .wp-block-gallery.is-cropped
    .blocks-gallery-image
    a,
  #blog-single-post-content
    .wp-block-gallery.is-cropped
    .blocks-gallery-image
    img,
  #blog-single-post-content .wp-block-gallery.is-cropped .blocks-gallery-item a,
  #blog-single-post-content
    .wp-block-gallery.is-cropped
    .blocks-gallery-item
    img {
    height: 100%;
    flex: 1;
    -o-object-fit: cover;
    object-fit: cover;
  }
}

#blog-single-post-content .wp-block-gallery .blocks-gallery-image,
#blog-single-post-content .wp-block-gallery .blocks-gallery-item {
  width: calc((100% - 16px) / 2);
}

#blog-single-post-content
  .wp-block-gallery
  .blocks-gallery-image:nth-of-type(2n),
#blog-single-post-content
  .wp-block-gallery
  .blocks-gallery-item:nth-of-type(2n) {
  margin-right: 0;
}

#blog-single-post-content .wp-block-gallery.columns-1 .blocks-gallery-image,
#blog-single-post-content .wp-block-gallery.columns-1 .blocks-gallery-item {
  width: 100%;
  margin-right: 0;
}

@media (min-width: 600px) {
  #blog-single-post-content .wp-block-gallery.columns-3 .blocks-gallery-image,
  #blog-single-post-content .wp-block-gallery.columns-3 .blocks-gallery-item {
    width: calc((100% - 32px) / 3);
    margin-right: 16px;
  }

  #blog-single-post-content .wp-block-gallery.columns-4 .blocks-gallery-image,
  #blog-single-post-content .wp-block-gallery.columns-4 .blocks-gallery-item {
    width: calc((100% - 48px) / 4);
    margin-right: 16px;
  }

  #blog-single-post-content .wp-block-gallery.columns-5 .blocks-gallery-image,
  #blog-single-post-content .wp-block-gallery.columns-5 .blocks-gallery-item {
    width: calc((100% - 64px) / 5);
    margin-right: 16px;
  }

  #blog-single-post-content .wp-block-gallery.columns-6 .blocks-gallery-image,
  #blog-single-post-content .wp-block-gallery.columns-6 .blocks-gallery-item {
    width: calc((100% - 80px) / 6);
    margin-right: 16px;
  }

  #blog-single-post-content .wp-block-gallery.columns-7 .blocks-gallery-image,
  #blog-single-post-content .wp-block-gallery.columns-7 .blocks-gallery-item {
    width: calc((100% - 96px) / 7);
    margin-right: 16px;
  }

  #blog-single-post-content .wp-block-gallery.columns-8 .blocks-gallery-image,
  #blog-single-post-content .wp-block-gallery.columns-8 .blocks-gallery-item {
    width: calc((100% - 112px) / 8);
    margin-right: 16px;
  }

  #blog-single-post-content
    .wp-block-gallery.columns-1
    .blocks-gallery-image:nth-of-type(1n),
  #blog-single-post-content
    .wp-block-gallery.columns-1
    .blocks-gallery-item:nth-of-type(1n),
  #blog-single-post-content
    .wp-block-gallery.columns-2
    .blocks-gallery-image:nth-of-type(2n),
  #blog-single-post-content
    .wp-block-gallery.columns-2
    .blocks-gallery-item:nth-of-type(2n),
  #blog-single-post-content
    .wp-block-gallery.columns-3
    .blocks-gallery-image:nth-of-type(3n),
  #blog-single-post-content
    .wp-block-gallery.columns-3
    .blocks-gallery-item:nth-of-type(3n),
  #blog-single-post-content
    .wp-block-gallery.columns-4
    .blocks-gallery-image:nth-of-type(4n),
  #blog-single-post-content
    .wp-block-gallery.columns-4
    .blocks-gallery-item:nth-of-type(4n),
  #blog-single-post-content
    .wp-block-gallery.columns-5
    .blocks-gallery-image:nth-of-type(5n),
  #blog-single-post-content
    .wp-block-gallery.columns-5
    .blocks-gallery-item:nth-of-type(5n),
  #blog-single-post-content
    .wp-block-gallery.columns-6
    .blocks-gallery-image:nth-of-type(6n),
  #blog-single-post-content
    .wp-block-gallery.columns-6
    .blocks-gallery-item:nth-of-type(6n),
  #blog-single-post-content
    .wp-block-gallery.columns-7
    .blocks-gallery-image:nth-of-type(7n),
  #blog-single-post-content
    .wp-block-gallery.columns-7
    .blocks-gallery-item:nth-of-type(7n),
  #blog-single-post-content
    .wp-block-gallery.columns-8
    .blocks-gallery-image:nth-of-type(8n),
  #blog-single-post-content
    .wp-block-gallery.columns-8
    .blocks-gallery-item:nth-of-type(8n) {
    margin-right: 0;
  }
}

#blog-single-post-content .wp-block-gallery .blocks-gallery-image:last-child,
#blog-single-post-content .wp-block-gallery .blocks-gallery-item:last-child {
  margin-right: 0;
}

#blog-single-post-content
  .wp-block-gallery
  .blocks-gallery-item.has-add-item-button {
  width: 100%;
}

#blog-single-post-content .wp-block-gallery.alignleft,
#blog-single-post-content .wp-block-gallery.alignright {
  max-width: 305px;
  width: 100%;
}

#blog-single-post-content .wp-block-gallery.aligncenter,
#blog-single-post-content .wp-block-gallery.alignleft,
#blog-single-post-content .wp-block-gallery.alignright {
  display: flex;
}

#blog-single-post-content
  .wp-block-gallery.aligncenter
  .blocks-gallery-item
  figure {
  justify-content: center;
}

.wp-block-image {
  max-width: 100%;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
}

.wp-block-image img {
  max-width: 100%;
  height: auto;
}

.wp-block-image.aligncenter {
  text-align: center;
}

.wp-block-image.alignfull img,
.wp-block-image.alignwide img {
  width: 100%;
}

.wp-block-image .aligncenter,
.wp-block-image .alignleft,
.wp-block-image .alignright,
.wp-block-image.is-resized {
  display: table;
  margin-left: 0;
  margin-right: 0;
}

.wp-block-image .aligncenter > figcaption,
.wp-block-image .alignleft > figcaption,
.wp-block-image .alignright > figcaption,
.wp-block-image.is-resized > figcaption {
  display: table-caption;
  caption-side: bottom;
}

.wp-block-image .alignleft {
  float: left;
  margin-right: 1em;
}

.wp-block-image .alignright {
  float: right;
  margin-left: 1em;
}

.wp-block-image .aligncenter {
  margin-left: auto;
  margin-right: auto;
}

.wp-block-image figcaption {
  margin-top: 0.5em;
  margin-bottom: 1em;
  color: #555d66;
  text-align: center;
  font-size: 13px;
}

/* Gutenburg styles */

#blog-single-post-content .wp-block-separator {
  border: none;
  border-bottom: 2px solid #8f98a1;
  margin-left: auto;
  margin-right: auto;
}
#blog-single-post-content
  .wp-block-separator:not(.is-style-wide):not(.is-style-dots) {
  max-width: 100px;
}
#blog-single-post-content
  .wp-block-separator.has-background:not(.is-style-dots) {
  border-bottom: none;
  height: 1px;
}
#blog-single-post-content
  .wp-block-separator.has-background:not(.is-style-wide):not(.is-style-dots) {
  height: 2px;
}

#blog-single-post-content .wp-block-separator.is-style-wide {
  border-bottom-width: 1px;
}

#blog-single-post-content .wp-block-separator.is-style-dots {
  background: none !important;
  border: none;
  text-align: center;
  max-width: none;
  line-height: 1;
  height: auto;
}
#blog-single-post-content .wp-block-separator.is-style-dots::before {
  content: "\00b7 \00b7 \00b7";
  color: currentColor;
  font-size: 20px;
  letter-spacing: 2em;
  padding-left: 2em;
  font-family: serif;
}

#blog-single-post-content .wp-block-media-text {
  /*!rtl:begin:ignore*/
  direction: ltr;
  /*!rtl:end:ignore*/
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 50% 1fr;
  grid-template-columns: 50% 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}
#blog-single-post-content .wp-block-media-text.has-media-on-the-right {
  -ms-grid-columns: 1fr 50%;
  grid-template-columns: 1fr 50%;
}

#blog-single-post-content
  .wp-block-media-text.is-vertically-aligned-top
  .wp-block-media-text__content,
#blog-single-post-content
  .wp-block-media-text.is-vertically-aligned-top
  .wp-block-media-text__media {
  -ms-grid-row-align: start;
  align-self: start;
}

#blog-single-post-content .wp-block-media-text .wp-block-media-text__content,
#blog-single-post-content .wp-block-media-text .wp-block-media-text__media,
#blog-single-post-content
  .wp-block-media-text.is-vertically-aligned-center
  .wp-block-media-text__content,
#blog-single-post-content
  .wp-block-media-text.is-vertically-aligned-center
  .wp-block-media-text__media {
  -ms-grid-row-align: center;
  align-self: center;
}

#blog-single-post-content
  .wp-block-media-text.is-vertically-aligned-bottom
  .wp-block-media-text__content,
#blog-single-post-content
  .wp-block-media-text.is-vertically-aligned-bottom
  .wp-block-media-text__media {
  -ms-grid-row-align: end;
  align-self: end;
}

#blog-single-post-content .wp-block-media-text .wp-block-media-text__media {
  /*!rtl:begin:ignore*/
  -ms-grid-column: 1;
  grid-column: 1;
  -ms-grid-row: 1;
  grid-row: 1;
  /*!rtl:end:ignore*/
  margin: 0;
}

#blog-single-post-content .wp-block-media-text .wp-block-media-text__content {
  direction: ltr;
  /*!rtl:begin:ignore*/
  -ms-grid-column: 2;
  grid-column: 2;
  -ms-grid-row: 1;
  grid-row: 1;
  /*!rtl:end:ignore*/
  padding: 0 8% 0 8%;
  word-break: break-word;
}

#blog-single-post-content
  .wp-block-media-text.has-media-on-the-right
  .wp-block-media-text__media {
  /*!rtl:begin:ignore*/
  -ms-grid-column: 2;
  grid-column: 2;
  -ms-grid-row: 1;
  grid-row: 1;
  /*!rtl:end:ignore*/
}

#blog-single-post-content
  .wp-block-media-text.has-media-on-the-right
  .wp-block-media-text__content {
  /*!rtl:begin:ignore*/
  -ms-grid-column: 1;
  grid-column: 1;
  -ms-grid-row: 1;
  grid-row: 1;
  /*!rtl:end:ignore*/
}

#blog-single-post-content .wp-block-media-text > figure > img,
#blog-single-post-content .wp-block-media-text > figure > video {
  max-width: unset;
  width: 100%;
  vertical-align: middle;
}

#blog-single-post-content
  .wp-block-media-text.is-image-fill
  figure.wp-block-media-text__media {
  height: 100%;
  min-height: 250px;
  background-size: cover;
}

#blog-single-post-content
  .wp-block-media-text.is-image-fill
  figure.wp-block-media-text__media
  > img {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

/*
* Here we here not able to use a mobile first CSS approach.
* Custom widths are set using inline styles, and on mobile,
* we need 100% width, so we use important to overwrite the inline style.
* If the style were set on mobile first, on desktop styles,
* we would have no way of setting the style again to the inline style.
*/
@media (max-width: 600px) {
  #blog-single-post-content .wp-block-media-text.is-stacked-on-mobile {
    -ms-grid-columns: 100% !important;
    grid-template-columns: 100% !important;
  }
  #blog-single-post-content
    .wp-block-media-text.is-stacked-on-mobile
    .wp-block-media-text__media {
    -ms-grid-column: 1;
    grid-column: 1;
    -ms-grid-row: 1;
    grid-row: 1;
  }
  #blog-single-post-content
    .wp-block-media-text.is-stacked-on-mobile
    .wp-block-media-text__content {
    -ms-grid-column: 1;
    grid-column: 1;
    -ms-grid-row: 2;
    grid-row: 2;
  }
  #blog-single-post-content
    .wp-block-media-text.is-stacked-on-mobile.has-media-on-the-right
    .wp-block-media-text__media {
    -ms-grid-column: 1;
    grid-column: 1;
    -ms-grid-row: 2;
    grid-row: 2;
  }
  #blog-single-post-content
    .wp-block-media-text.is-stacked-on-mobile.has-media-on-the-right
    .wp-block-media-text__content {
    -ms-grid-column: 1;
    grid-column: 1;
    -ms-grid-row: 1;
    grid-row: 1;
  }
}

/* Custom Gutenberg */

#blog-single-post-content .wp-block-separator {
  border-color: #eee;
  margin: 1em 0;
}
